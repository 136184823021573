<template>
  <div class="keenMindDetail">
    <div v-loading="loading"></div>
    <div class="crumb" v-if="clientWidth > 750">
      <breadcrumb :refer="refer"></breadcrumb>
    </div>
    <div class="keenMindDetail-banner">
      <img v-if="keenDetail.banner" :src="keenDetail.banner" alt="">
      <img v-else src="@/assets/PC/keenMind/keendetailbanner.png" alt="">
      <h1 v-if="!keenDetail.banner" class="hidden">{{ keenDetail.title }}</h1>
      <p v-if="!keenDetail.banner" class="flex1"><van-icon name="clock" class="mr co-voi" /> {{ keenDetail.startTimeStr
        }} - {{ keenDetail.endTimeStr }}</p>
    </div>
    <div v-if="keenDetail.classify != 1 && keenDetail.classify != 3">
      <h1 v-if="keenDetail.type == 0" class="keenMindDetail-title">【线上】{{ keenDetail.title }}</h1>
      <h1 v-else class="keenMindDetail-title">【线下】{{ keenDetail.title }}</h1>
    </div>
    <div v-else>
      <h1 class="keenMindDetail-title">{{ keenDetail.title }}</h1>
    </div>
    <div class="flex3 date-signUp">
      <div class="keenMindDetail-date co-999">活动时间： {{ keenDetail.startTimeStr }} - {{ keenDetail.endTimeStr }}</div>
      <div class="signUp flex">

        <div v-if="keenDetail.classify == 0" class="ml10">
          <el-button v-if="keenDetail.status === 2" type="warning" :size="clientWidth > 750 ? '' : 'mini'"
            disabled>已结束</el-button>
          <el-button v-if="keenDetail.status !== 2 && !keenDetail.isSignUp" type="info"
            :size="clientWidth > 750 ? '' : 'mini'" @click="signUp(keenDetail.id)">我要报名</el-button>
          <el-button v-if="keenDetail.status !== 2 && keenDetail.isSignUp" type="warning"
            :size="clientWidth > 750 ? '' : 'mini'" disabled>已报名</el-button>
        </div>

        <div class="flex" v-else-if="keenDetail.classify == 1 || keenDetail.classify == 2">
          <div v-if="keenDetail.isOkEdit && keenDetail.type == 0" class="flex">
            <el-button type="info" :size="clientWidth > 750 ? '' : 'mini'" @click="goActivityPreview">作品展示</el-button>
            <el-button type="info" :size="clientWidth > 750 ? '' : 'mini'" @click="goActivityVote">作品投票</el-button>
          </div>
          <div v-if="keenDetail.status !== 2" class="flex ml10">
            <el-button v-if="!keenDetail.isSignUp" type="info" :size="clientWidth > 750 ? '' : 'mini'"
              @click="goActivitySignUp">我要报名</el-button>
            <el-button v-if="keenDetail.isSignUp && keenDetail.isSubmit == 0 && keenDetail.type == 0" type="info"
              :size="clientWidth > 750 ? '' : 'mini'" @click="goActivityFile">提交作品</el-button>
            <el-button
              v-if="(keenDetail.isSignUp && keenDetail.type == 1) || (keenDetail.isSignUp && keenDetail.isSubmit && keenDetail.type == 0)"
              type="warning" :size="clientWidth > 750 ? '' : 'mini'" disabled>已报名</el-button>
          </div>
          <div v-else class="flex ml10">
            <el-button v-if="keenDetail.status === 2" type="warning" :size="clientWidth > 750 ? '' : 'mini'"
              disabled>已结束</el-button>
          </div>
        </div>

        <div class="flex" v-else-if="keenDetail.classify == 3">
          <div v-if="keenDetail.isOkEdit" class="flex">
            <el-button type="info" :size="clientWidth > 750 ? '' : 'mini'" @click="goActivityPreview">作品展示</el-button>
          </div>
          <div v-if="keenDetail.status !== 2" class="flex ml10">
            <el-button v-if="!keenDetail.isSignUp" type="info" :size="clientWidth > 750 ? '' : 'mini'"
              @click="goActivitySignUp">我要报名</el-button>
            <el-button v-else type="warning" :size="clientWidth > 750 ? '' : 'mini'" disabled>已报名</el-button>
          </div>
          <div v-else class="flex ml10">
            <el-button v-if="keenDetail.status === 2" type="warning" :size="clientWidth > 750 ? '' : 'mini'"
              disabled>已结束</el-button>
          </div>
        </div>

      </div>

    </div>
    <div class="co-999" style="padding: 5px 0 15px 0">{{ keenDetail.introduction }}</div>
    <div v-if="keenDetail.classify != 1 && keenDetail.classify != 3" class="Tag flex4">
      <div v-for="(item, index) in keenDetail.tags" :key="index" class="tag-item">{{ item }}</div>
    </div>
    <div v-html="keenDetail.context" class="description"></div>
  </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { common_msg_update, GetWxShares } from "@/utils/common.js";
import * as Keen from '@/api/KeenMind.js';
import { dataState } from "@/api/all.js";

export default {
  name: "keenMindDetail",
  data() {
    return {
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
      ],
      loading: true,                          // !loading
      keenDetail: {},                          // !详情页数据
    }
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$toast('页面异常，正在返回首页');
      setTimeout(() => {
        this.$router.push('/Home/keenMind');
      }, 500);
      return false;
    }
    this.Init();
    // 数据统计
    const data = {
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "keenMindDetail",
      "relationId": this.$route.query.id,
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid', res.data);
    });
  },
  methods: {
    // !获取详情数据
    Init() {
      const Id = { id: this.$route.query.id }
      Keen.GetActivityDetail(Id).then(res => {
        if (res.status == 1) {
          this.keenDetail = res.data;
          if (this.refer.length < 3) {
            this.refer.push({ 'name': res.data.title })
          }
          const share = {
            title: res.data.title,
            desc: ''
          }
          GetWxShares(share);
        } else {
          this.$toast(res.message);
          this.$router.push('/Home/keenMind');

        }
        this.loading = false;
      })
    },

    // !我要报名
    signUp(id) {
      const Id = { "activityId": id }
      if (this.$store.state.token) {
        Keen.SignUp(Id).then(res => {
          this.$toast(res.message);
          if (res.status == 1) {
            this.Init();
          } else {
          }
        }).then(() => {
          common_msg_update();
        })
      } else {
        this.$toast('请先登录后再来报名吧！');
        if (this.$route.path.includes('keenMindDetail')) {
          sessionStorage.setItem("Fence", this.$route.path + '?id=' + this.$route.query.id)
        }
        setTimeout(() => {
          this.$router.push('/Login');
        }, 500)
      }
    },
    // !去活动报名页面
    goActivitySignUp() {
      if (this.$store.state.token) {
        this.$router.push('/Home/ActivitySignUp?id=' + this.$route.query.id);
      } else {
        this.$toast('请先登录后再来报名吧！');
        if (this.$route.path.includes('keenMindDetail')) {
          sessionStorage.setItem("Fence", this.$route.path + '?id=' + this.$route.query.id)
        }
        setTimeout(() => {
          this.$router.push('/Login?redirectUrl=' + this.$route.fullPath);
        }, 500)
      }
    },
    // !去提交作品页面
    goActivityFile() {
      this.$router.push('/Home/ActivityFile?id=' + this.$route.query.id);
    },
    // !去作品展示页面
    goActivityPreview() {
      this.$router.push('/Home/ActivityPreview?id=' + this.$route.query.id);
    },
    // !去作品投票页面
    goActivityVote() {
      this.$router.push('/Home/ActivityVote?id=' + this.$route.query.id);
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
  },
  components: {
    breadcrumb,
  },
}
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.keenMindDetail {
  .keenMindDetail-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .date-signUp {
    margin-bottom: 23px;
  }

  .keenMindDetail-date {
    font-size: 16px;
  }

  .Tag {
    margin-bottom: 40px;
  }

  .el-button--warning.is-disabled {
    background: #909399;
    border-color: #909399;
  }
}

@media (max-width: 750px) {
  .keenMindDetail {
    .keenMindDetail-title {
      font-size: vw(32);
      margin: vw(39) 0 vw(24) 0;
    }

    .date-signUp {
      display: block;
      margin-bottom: vw(41);
    }

    .keenMindDetail-date {
      font-size: vw(24);
      margin-bottom: vw(30);
    }

    .Tag {
      margin-bottom: vw(40)
    }
  }
}
</style>